<template>
  <div>
    <sdPageHeader title="Substatussen">
    </sdPageHeader>
    <Main v-if="errorSubStatus">
      <sdCards title="">
        <a-result status="warning" title="Er is een fout opgetreden bij het ophalen van de gegevens."
                  style="padding: 0">
          <template #extra>
            <Button class="ant-btn ant-btn-primary" type="primary" size="large" @click="$router.replace('/substatus')">
              Naar substatusses
            </Button>
          </template>
        </a-result>
      </sdCards>
    </Main>
    <Main v-if="!errorSubStatus">
      <div class="user-info-form">
        <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
            <a-form :model="form" :rules="rules" layout="vertical" @finish="UpdateSubStatus">

              <a-row :gutter="30">
                <a-col :sm="12" :xs="12" class="mb-25">
                  <a-form-item label="Naam" name="name">
                    <a-input v-model:value="form.name" placeholder=""/>
                  </a-form-item>
                </a-col>
                <a-col :sm="12" :xs="12" class="mb-25">
                  <a-form-item label="Hoofdstatus" name="parentStatus"
                               :rules="{required: true,message: 'Dit veld is verplicht'}">
                    <a-select v-model:value="form.parentStatus"
                              :disabled="true"
                              size="large" class="sDash_fullwidth-select"
                              :allowClear="true">
                      <a-select-option name="type" v-for="(item,index) in this.parentStatusses"
                                       :key="index" :value="item.status">
                        {{ item.displayName }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

              </a-row>
              <a-row :gutter="30">
                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item label="Kleur" name="color">
                    <a-input
                        :style="{ height: '55px' }"
                        name="color"
                        type="color"
                        v-model:value="form.color"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :sm="12" :xs="24">
                  <div class="sDash_form-action">
                    <sdButton v-if="$can('update', 'substatus')" class="btn-signin" type="primary"> Opslaan</sdButton>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </sdCards>
        </BasicFormWrapper>
      </div>

      <sdCards>
        <a-row>
          <a-col :sm="12" :xs="24">
            <div class="sDash_form-action">
              <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.replace('/substatus')">
                Terug
              </sdButton>
            </div>
          </a-col>
        </a-row>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import {BasicFormWrapper, Main} from '../styled';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'SubStatusEdit',
  components: {
    BasicFormWrapper,
    Main,
  },
  setup() {
    const {state, dispatch} = useStore();
    const {params} = useRoute();
    const form = reactive(computed(() => state.substatus.substatus));
    const errorSubStatus = computed(() => state.substatus.errorSubStatus);
    const allPermissions = computed(() => state.substatus.allPermissions);
    const parentStatusses = computed(() => state.substatus.parentStatusses);
    const rules = {
      name: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
      description: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
    };
    const UpdateSubStatus = () => {
      dispatch('patchSubStatus', {
        id: params.id,
        name: form.value.name,
        parentStatus: form.value.parentStatus,
        color: form.value.color,
      });
    };

    onMounted(() => {
      dispatch('getSubStatus', params.id);
    });

    return {
      form,
      rules,
      errorSubStatus,
      UpdateSubStatus,
      allPermissions,
      parentStatusses
    };
  },
});
</script>
